import { FaGithub, FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <div className=" w-full p-4 bg-gray-700 flex justify-between items-center text-white border-t border-gray-500">
      <div>
        <small className="">copyright© @ Kedar Kandel</small>
      </div>
      <div className=" flex items-center gap-4">
        <a
          href="https://www.linkedin.com/in/kedar-kandel-3b6755177/"
          target="blank"
        >
          <FaLinkedin  size={"30px"} fill="white" />
        </a>
        <a href="https://github.com/KedarKandel" target="blank">
          <FaGithub size={"30px"} fill="white" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
