import { Link as ScrollLink } from "react-scroll";
import { motion } from "framer-motion";

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileNav = ({ isOpen, setIsOpen }: Props) => {
  return (
    <motion.div
    initial={{ x: "100%", opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: "100%", opacity: 0 }}
    transition={{ type: "tween", duration: 0.5 }}
    className="fixed md:hidden top-0 right-0 min-h-screen w-8/12 bg-[#373737] flex justify-center items-center"
  >
      <div
        className=" flex flex-col gap-6 text-[#fff] text-xl font-semibold p-2 items-center justify-center"
        style={{ textShadow: "2px 2px 3px rgba(0, 0, 0, 0.2)" }}
      >
       
        <ScrollLink
          to="services"
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
          className=" hover:text-gray-500 transition-all cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <span>Services</span>
        </ScrollLink>
        <ScrollLink
          to="about_me"
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
          className=" hover:text-gray-500 transition-all cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <span>About Me</span>
        </ScrollLink>
        <ScrollLink
          to="education"
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
          className=" hover:text-gray-500 transition-all cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <span>Edu & Experiences</span>
        </ScrollLink>
        <ScrollLink
          to="projects"
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
          className=" hover:text-gray-500 transition-all cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <span>Projects</span>
        </ScrollLink>
        <ScrollLink
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={900}
          className=" hover:text-gray-500 transition-all cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <span>Contact</span>
        </ScrollLink>
        <a
          href="https://mern-blog-app-39y0.onrender.com/"
          target="_blank"
          rel="noreferrer"
          className=" hover:text-gray-500"
          onClick={() => setIsOpen(false)}
        >
          Blogs
        </a>
      </div>
    </motion.div>
  );
};

export default MobileNav;
