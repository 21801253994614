import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const Services = () => {
  const [ref, inView] = useInView({ threshold: 0 });
  const [animationTriggered, setAnimationTriggered] = useState(false);

  useEffect(() => {
    if (inView && !animationTriggered) {
      setAnimationTriggered(true);
    }
  }, [inView, animationTriggered]);
  return (
    <div
      id="services"
      className="w-full p-4 shadow-sm  "
      style={{ textShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)" }}
    >
      <h2 className="text-2xl font-bold mt-4 mb-4 text-blue-500">Skills & Services</h2>
      <p className="mb-6 text-lg leading-7 ">
        Bringing visions to life, one pixel at a time. Welcome to my web
        development services, where I specialize in turning ideas into
        captivating digital experiences. With expertise in frontend, backend,
        and full-stack development, I ensure exceptional results within your
        timeline and budget. Let's collaborate to create your next digital
        masterpiece!
      </p>
      {/* <p>Welcome to my web development services, where I craft tailored solutions to bring your ideas to life. With expertise in frontend, backend, and full-stack development, I'm committed to delivering exceptional results on time and within budget. Whether you have a clear vision or need design guidance, I offer flexible collaboration options to ensure your satisfaction. Let's create captivating digital experiences together!</p> */}
      <div className=" w-full  p-2 md:p-10  flex justify-center shadow-md items-center gap-6 border-2 border-purple-300  bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 ">
        <div
          ref={ref}
          className={` grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3  flex-wrap gap-8`}
        >
          {/* Frontend Development Section */}
          <section
            ref={ref}
            className={`flex-1  shadow-xl p-6 rounded-lg transition-all hover:bg-gray-300 border-4 border-t-8 border-blue-700 ${
              inView ? "animate_services_item" : ""
            }`}
          >
            <h3 className=" text-xl font-semibold mb-2 text-blue-600">
              Frontend Development
            </h3>
            <p className="mb-2">
              I specialize in building modern web applications interfaces, be it
              static or dynamic using:
            </p>
            <ul className="  list-disc list-inside mb-4 lg:ms-6 p-2 md:p-4 space-y-1  text-blue-700 bg-white rounded-md">
              <li>HTML, CSS, JavaScript</li>
              <li>TypeScript</li>
              <li>React</li>
              <li>React-Context</li>
              <li>Context API</li>
              <li>React Query</li>
              <li>React-Redux</li>
              <li>Redux-toolkit</li>
              <li>Tailwind CSS</li>
              <li>Shadcn</li>
              <li>SCSS</li>
              <li>Bootstrap</li>
              <li>MaterialUI</li>
              <li>Jest and React testing library</li>
              <li>Netlify</li>
              <li>... more</li>
            </ul>
          </section>

          {/* Backend Development Section */}
          <section
            ref={ref}
            className={`flex-1 shadow-xl p-6 rounded-lg transition-all hover:bg-gray-300 border-4 border-t-8 border-teal-600 ${
              inView ? "animate_services_item" : ""
            }`}
          >
            <h3 className=" text-xl font-semibold mb-2 text-teal-600">
              Backend Development
            </h3>
            <p className="mb-2">
              Some of the technologies I use to build backend business
              solutions:
            </p>
            <ul className="list-disc list-inside mb-4 md:ms-6 p-4 space-y-1 text-teal-700 bg-white rounded-md">
              <li>TypeScript</li>
              <li>JavaScript</li>
              <li>Node.js</li>
              <li>Express.js</li>
              <li>Java</li>
              <li>Spring Boot</li>
              <li>Websockets and Real-time</li>
              <span className=" ms-6">Communication</span>
              <li>OAuth 2.0</li>
              <li>Firebase</li>
              <li>MongoDB</li>
              <li>Mongoose</li>
              <li>PostgreSQL</li>
              <li>MySQL</li>
              <li>Mariadb</li>
              <li>Supertest, playright tests</li>
              <li>... more</li>
            </ul>
          </section>

          {/* Full-stack Development Section */}
          <section
            ref={ref}
            className={`flex-1  shadow-xl p-6 rounded-lg transition-all hover:bg-gray-300 border-4 border-t-8 border-yellow-400 ${
              inView ? "animate_services_item" : ""
            }`}
          >
            <h3 className="text-xl font-semibold mb-2 text-pink-500">
              Full-stack Development
            </h3>
            <p className="mb-2">
              I can offer complete solutions for full-stack development,
              including:
            </p>
            <ul className="list-disc list-inside  md:ms-6 p-4 space-y-1 text-pink-600 bg-white rounded-md">
              <li>Project documentation</li>
              <li>Version Control (Git)</li>
              <li>React</li>
              <li>
                NodeJs, Typescript, <span className="ms-6">ExpressJs</span>{" "}
              </li>
              <li>Java, Spring Boot</li>
              <li>RESTful APIs</li>
              <li>Authentication and</li>
              <span className=" ms-6">Authorization</span>
              <li>Third-party API Integration</li>
              <li>Database integration</li>
              <li>SEO</li>
              <li>Heroku, Hostinger, Netlify,</li>
              <span className=" ms-6"> Render, Vercel</span>
              <li>e2e Testing</li>
              <li>Docker and Containerization</li>
              <li>Nginx</li>
              <li>Github Actions</li>
              <li>AWS EC2, Route 53</li>
              <li>CloudFront, ACM</li>
              <li>... more</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Services;
