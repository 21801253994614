import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const Education = () => {
  const [ref, inView] = useInView({ threshold: 0 });

  const [animationTriggered, setAnimationTriggered] = useState(false);

  useEffect(() => {
    if (inView && !animationTriggered) {
      setAnimationTriggered(true);
    }
  }, [inView, animationTriggered]);
  return (
    <div
      id="education"
      className=" w-full p-4 flex flex-col justify-center text-gray-800"
      style={{ textShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)" }}
    >
      <h2 className="text-2xl font-bold mt-4 mb-4 md:mb-6 text-blue-500">
        Education & Experience
      </h2>
      <p className="mb-4 text-lg">
        With a commitment to academic excellence, my educational journey
        reflects a dedication to learning and personal growth, shaping a
        foundation built on curiosity, perseverance, and a thirst for knowledge.
      </p>
      <div
        ref={ref}
        className={`relative p-4 md:p-20 font-serif text-gray-400 text-lg  shadow-md  rounded-xl bg-gradient-to-r from-gray-700 via-gray-800 to-gray-700 ${
          inView ? "timeline rounded-sm" : ""
        }`}
      >
        {/* Should keep going*/}
        <div
          ref={ref}
          className={` relative  md:w-[50%] p-2 shadow-lg ${
            inView ? "timeline_items left-0 " : ""
          }`}
        >
          <div className="relative bg-gray-900 flex flex-col gap-1 p-4  border-2 border-gray-400 rounded-md shadow-lg md:mr-2 ">
            <h1>
              <strong>
                To stop learning is to stop evolving; embrace knowledge
                endlessly.
              </strong>
            </h1>
            <p className="bg-gray-800 p-2 max-w-max self-end text-3xl rounded-lg">👉🔠</p>
            <p className="text-5xl bg-gray-800 flex justify-center items-center rounded-lg">
              <span>🙋🏻</span>
              <span className=" text-6xl">🇳🇵</span>
            </p>
            <p className="bg-gray-800 p-2 max-w-max self-end text-3xl rounded-lg">👉🛠️</p>
            <span className="right_arrow border"></span>
          </div>
        </div>

        {/*Laurea Business Information Technology */}

        <div
          className={` relative md:w-[50%] p-2 shadow-lg ${
            inView ? "timeline_items left-0 md:left-[50%]" : ""
          }`}
        >
          <div className=" relative bg-gray-900 flex flex-col gap-1 p-4 border-2 border-gray-400 rounded-md shadow-lg md:ml-4">
            <h1 className="font-semibold">
              Degree Programme in Business Infromation Technology, Developing
              Digital Services.
            </h1>
            <strong className=" flex items-center gap-1">
              Institution:
              <span className=" font-normal">
                Laurea University of Applied Sciences
              </span>
            </strong>
            <strong className=" flex items-center gap-2">
              Location:
              <span className="font-normal">Espoo, Finland</span>
            </strong>
            <strong className=" flex items-center gap-1">
              Year:<small className=" font-normal">2024-present-2027</small>
            </strong>
            <p className="flex gap-1">
              <strong>Courses:</strong>The ICT Environment and Infrastructure,
              Fundamentals of Programming, Information Management and Databases,
              Data Networks and Information Security, Full Stack Development,
              Service Design, Data-driven Decision Making, Defining and
              Designing a software Product, Programming with Javascript, Python,
              C,C++,DevOps Fundamentals, Project Management and communication
            </p>
            <span className="left_arrow"></span>
          </div>
        </div>

        {/* Self Employed*/}
        <div
          ref={ref}
          className={` relative  md:w-[50%] p-2 shadow-lg ${
            inView ? "timeline_items left-0 " : ""
          }`}
        >
          <div className="relative bg-gray-900 flex flex-col gap-1 p-4  border-2 border-gray-400 rounded-md shadow-lg md:mr-2 ">
            <h1>
              <strong> Full-stack Developer</strong>
            </h1>
            <p className=" flex items-center gap-1">
              <strong>Freelance</strong>
            </p>
            <p className=" flex items-center gap-1">
              Duration: <small>Jan 2024 - Nov 2024</small>
            </p>
            <p className="flex gap-1">
              <strong>Tech:</strong> Javascript, Typescript, React,
              Redux-Toolkit, Tailwindcss, shadcn/ui, NodeJs, ExpressJS, MongoDB,
              Postgres, Java, Springboot, Jest and React testing library,
              Docker, github Actions, AWS EC2, Route 53, CloudFront, ACM
            </p>
            <span className="right_arrow border"></span>
          </div>
        </div>

        {/* Integrify*/}
        <div
          className={` relative md:w-[50%]  p-2  shadow-lg ${
            inView ? "timeline_items left-0 md:left-[50%]" : ""
          }`}
        >
          <div className=" relative bg-gray-900  flex flex-col gap-1  p-4 border-2 border-gray-400  rounded-md shadow-lg md:ml-4">
            <h1>
              Position: <strong> Full-stack Developer</strong>
            </h1>
            <p className=" flex items-center gap-1">
              Company/Org:<strong>Integrify</strong>
            </p>
            <p className=" flex items-center gap-1">
              Location: <span>Helsinki, Finland</span>
            </p>
            <p>
              Duration:{" "}
              <span className=" text-sm font-bold">Feb 2023 - Jan 2024</span>
            </p>
            <p className="flex gap-1">
              <strong>Tech:</strong> Java, Springboot, IntelliJ IDEA,
              Javascript, Typescript, Web Accessibility, React, Redux-Toolkit,
              Tailwindcss, NodeJs, ExpressJS, MongoDB, Postgres, Jest and React
              testing library, Docker, CI/CD, ...
            </p>
            <span className="left_arrow"></span>
          </div>
        </div>

        {/* Aveksana*/}
        <div
          ref={ref}
          className={` relative md:w-[50%] p-2 shadow-lg ${
            inView ? "timeline_items left-0 " : ""
          }`}
        >
          <div className="relative bg-gray-900 flex flex-col gap-1 p-4 border-2 border-gray-400 rounded-md shadow-lg md:mr-2">
            <h1>
              Position: <strong>Junior Full-stack Developer</strong>
            </h1>
            <p className=" flex items-center gap-1">
              Company/Org:<strong>Aveksana</strong>
            </p>
            <p className=" flex items-center gap-1">
              Location: <span>Tampere, Finland</span>
            </p>
            <p>
              Duration:{" "}
              <small className=" font-bold">June 2022 - Feb 2023</small>
            </p>
            <p className="flex gap-1">
              <strong>Tech:</strong> Javascript, Typescript, React,
              Redux-Toolkit, SCSS, NodeJs, ExpressJS, MongoDB, Scrapy,
              playwright e2e tests, Docker, CI/CD, ...
            </p>
            <span className="right_arrow"></span>
          </div>
        </div>

        {/* Udemy*/}
        <div
          className={` relative md:w-[50%] p-2 shadow-lg ${
            inView ? "timeline_items left-0 md:left-[50%]" : ""
          }`}
        >
          <div className=" relative bg-gray-900 flex flex-col gap-1  p-4 border-2 border-gray-400  rounded-md shadow-lg md:ml-4">
            <h1>
              Title: <strong>The Complete 2022 Web Development Bootcamp</strong>{" "}
            </h1>
            <p className="flex items-center gap-1">
              Platform:<strong>Udemy</strong>
            </p>
            <p className="flex items-center gap-1">
              Completion: <small className=" font-bold">Jan 2022</small>
            </p>
            <p className="flex gap-1">
              <strong>Courses:</strong> HTML,CSS,Javascript, JQuery, React,
              Redux, Bootstrap, Nodejs, Expressjs, MySQL, MongoDb,
              Authentication and Authorization
            </p>
            <span className="left_arrow"></span>
          </div>
        </div>
        {/* Next-Gen lab*/}
        <div
          ref={ref}
          className={` relative md:w-[50%] p-2 shadow-lg ${
            inView ? "timeline_items left-0 " : ""
          }`}
        >
          <div className="relative bg-gray-900 flex flex-col gap-1 p-4 border-2 border-gray-400 rounded-md shadow-lg md:mr-2">
            <h1 className=" flex items-center gap-1">
              Project: <strong>Bike Sharing Application </strong>
            </h1>
            <p className=" flex items-center gap-1">
              Location: Centria UAS{" "}
              <small className=" font-bold">(Next-Gen Project)</small>
            </p>
            <p className=" flex items-center gap-1">
              Year:<small className=" font-bold">2019</small>
            </p>
            <p className="flex  gap-1">
              <span className=" font-bold ">Focus:</span> Team work, Data
              filtering, Data Reporting and Analytics, SAP S4HANA, Excel,
              Python, Interface development
            </p>
            <span className="right_arrow"></span>
          </div>
        </div>
        {/* SAP-ERP*/}
        <div
          className={` relative md:w-[50%]  p-2 shadow-lg ${
            inView ? "timeline_items left-0 md:left-[50%]" : ""
          }`}
        >
          <div className="relative bg-gray-900 flex flex-col gap-1 p-4 border-2 border-gray-400 rounded-md shadow-lg md:ml-4">
            <h1 className=" flex gap-1">
              Certificate:
              <strong>
                SAP Certified Application Associate - Business Foundation &
                Integration with SAP ERP 6.07
              </strong>
            </h1>
            <p className="flex gap-1 items-center">
              Issued:<small className=" font-bold"> May 2018</small>
            </p>
            <p className="flex gap-1 items-center font-bold">
              Credential ID: <small className=" font-normal">0019377773</small>
            </p>
            <p className="flex gap-1">
              Company: <strong>SAP</strong>
            </p>
            <span className="left_arrow"></span>
          </div>
        </div>
        {/* BBA-Centria*/}
        <div
          ref={ref}
          className={` relative md:w-[50%] p-2 shadow-lg ${
            inView ? "timeline_items left-0 " : ""
          }`}
        >
          <div className="relative bg-gray-900 flex flex-col gap-1 p-4 border-2 border-gray-400 rounded-md shadow-lg md:mr-2">
            <h1 className=" font-semibold">
              BBA, Degree Program in Business Management(ERP)
            </h1>
            <p className=" flex items-center gap-1 font-bold">
              Institution:
              <span className=" font-normal">
                Centria University of Applied Sciences
              </span>
            </p>
            <p className=" flex items-center gap-1 font-bold">
              Location: <span className=" font-normal">Kokkola, Finland</span>
            </p>
            <p className=" flex items-center gap-1 font-bold">
              Year:<small className=" font-normal">2016-2019</small>
            </p>
            <p className=" flex items-center gap-1 font-bold">
              Thesis: <span className=" font-normal">4</span>
            </p>
            <p className=" flex items-center gap-1 font-bold">
              Grade: <span className=" font-normal">3.9</span>
            </p>
            <p className="flex gap-1">
              <strong>Courses:</strong> Entreprise Resource Planning,
              Business-Statistics, Accounting, Finance, Economics, Marketing,
              CRM
            </p>
            <span className="right_arrow"></span>
          </div>
        </div>

        {/* BBS TU*/}
        <div
          className={` relative md:w-[50%] p-2 shadow-lg ${
            inView ? "timeline_items left-0 md:left-[50%]" : ""
          }`}
        >
          <div className=" relative bg-gray-900 flex flex-col gap-1 p-4 border-2 border-gray-400 rounded-md shadow-lg md:ml-4">
            <h1 className=" font-semibold">
              Bachelor's of Business studies(B.B.S)
            </h1>
            <strong className=" flex items-center gap-1">
              Institution:
              <span className=" font-normal">Tribhuvan University</span>
            </strong>
            <strong className=" flex items-center gap-2">
              Location:
              <span className="font-normal">Kathmandu, Nepal</span>
            </strong>
            <strong className=" flex items-center gap-1">
              Year:<small className=" font-normal">2012-2015</small>
            </strong>
            <p className="flex gap-1">
              <strong>Courses:</strong> Business Statistics, Corporate Finance,
              Investments, Business Law, Financial Institutions and Markets,
              Investment, Quality Management, Marketing, ...
            </p>
            <span className="left_arrow"></span>
          </div>
        </div>

        {/* 10+2*/}
        <div
          ref={ref}
          className={` relative md:w-[50%] p-2 shadow-lg ${
            inView ? "timeline_items left-0 " : ""
          }`}
        >
          <div className="relative bg-gray-900 flex flex-col gap-1 p-4 border-2 border-gray-400 rounded-md shadow-lg md:mr-2">
            <h1 className=" font-semibold">
              Management 10+2 (Computer Science)
            </h1>

            <strong className=" flex items-center gap-1">
              Institution:
              <span className=" font-normal">Balkumari College</span>
            </strong>
            <p className=" flex items-center gap-1">
              <strong className=" flex items-center gap-1">
                Location: <small className=" font-normal">Chitwan, Nepal</small>
              </strong>
            </p>
            <p className=" flex items-center gap-1 font-bold">
              Year:<small className=" font-normal">2008-2010</small>
            </p>
            <p className="flex gap-1">
              <strong className="flex items-center gap-1">
                Division: <small className=" font-normal">Distinction</small>
              </strong>
            </p>
            <p className="flex gap-1">
              <strong>Courses: </strong>Computer Science, Business Mathematics,
              Organizational management, Accountancy, Finance, ...
            </p>
            <span className="right_arrow"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
