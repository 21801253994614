import { motion } from "framer-motion";

const ProjectCounter = () => {
  return (
    <motion.div
      initial={{ x: 100, opacity: 0, }}
      animate={{ x: 0, opacity: 1, rotate: 45 }}
      exit={{ y: 300, opacity: 0,  }}
      whileHover={{ rotate: 360 }} 
      transition={{  duration:2 }}
      className=" absolute hidden md:block bg-white z-100 top-40 right-20  justify-center items-center border-4 p-3 hover:border-yellow-500   hover:rotate-0 rounded-full shadow-lg  transition-all duration-300 ease-in-out"
    >
      <h2 className="font-bold text-sm text-pink-600 hover:text-pink-400 text-center">Projects</h2>
      <p className="text-xl font-bold mt-2 text-yellow-500 hover:text-yellow-400 text-center">25+</p>
    </motion.div>
  );
};

export default ProjectCounter;
