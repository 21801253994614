import Header from "../components/Header";
import Footer from "../components/Footer";
import Services from "../components/Services";
import Contact from "../components/Contact";
import AboutMe from "../components/AboutMe";
import Projects from "../components/Projects";
import Education from "../components/Education";

const Home = () => {
  return (
    <div className=" w-full relative flex flex-col items-center justify-center">
      <Header />
      <Services />
      <AboutMe />
      <Education />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
